'use client';
import Link from 'next/link';
import { IUser } from '@/types';

import { usePathname } from 'next/navigation';

import DropdownMenu from '@/ui/DropdownMenu';
import { OrganizationIcon, RepoIcon, PersonIcon, SignOutIcon } from '@primer/octicons-react';
import * as Icon from '@/ui/Icon';

import { UserAvatar } from '@/components/Avatar';
import Button from '@/ui/Button';

import * as Styled from './styled';
import CompleteAccountButton from '@/components/CompleteAccount/CompleteAccountButton';

const CreateMenu: React.FC<{}> = ({}) => (
  <DropdownMenu>
    <DropdownMenu.Trigger>
      <Button.Basic inline style={{ paddingLeft: 15, paddingRight: 15 }} as="div">
        <Icon.Add />
        <Icon.DownSmall width={12} />
      </Button.Basic>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      <DropdownMenu.Group>
        <DropdownMenu.Item>
          <Link href="/repositories/new">
            <RepoIcon />
            New repository
          </Link>
        </DropdownMenu.Item>
      </DropdownMenu.Group>
      <DropdownMenu.Group>
        <DropdownMenu.Item>
          <Link href="/account/organizations/new">
            <OrganizationIcon />
            New organization
          </Link>
        </DropdownMenu.Item>
      </DropdownMenu.Group>
    </DropdownMenu.Content>
  </DropdownMenu>
);

const NavigateMenu: React.FC<{ user: IUser; onLogout?: () => void }> = ({ user, onLogout }) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <div style={{ cursor: 'pointer', display: 'flex', gap: 5 }}>
          {/* <UserAvatar user={user} size={20} /> */}@{user?.username}
          <Icon.DownSmall width={12} />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content sideOffset={15}>
        <DropdownMenu.Label>
          Signed in as <b>{user.username}</b>
        </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Group>
          <DropdownMenu.Item>
            <Link href="/settings/profile">
              <PersonIcon />
              My profile
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <Link href="/">
              <RepoIcon />
              My repositories
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <Link href="/settings/organizations">
              <OrganizationIcon />
              My organizations
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <Link href={`/collections/${user.username}`}>
              <Icon.Compass />
              My collections
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <Link href="/starred">
              <Icon.Star />
              My stars
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={onLogout}>
            <SignOutIcon />
            Sign out
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

const UserContext: React.FC<{
  user?: IUser;
  variant?: 'default' | 'marketing';
  onLogout?: () => void;
}> = ({ user, variant = 'marketing', onLogout }) => {
  const pathmane = usePathname();
  const loginPath = `/login?redirect=${encodeURIComponent(pathmane)}`;
  const registerPath = `/register?redirect=${encodeURIComponent(pathmane)}`;

  return user?.id ? (
    user.status === 'incomplete' ? (
      <Styled.UserInfo>
        <CompleteAccountButton />
        <Button.Text onClick={onLogout}>Log out</Button.Text>
      </Styled.UserInfo>
    ) : (
      <Styled.UserInfo>
        <CreateMenu />
        <NavigateMenu user={user} onLogout={onLogout} />
      </Styled.UserInfo>
    )
  ) : (
    <Styled.SignInSignUp>
      <Button.Text href={loginPath} testId="sign-in">
        LOG IN
      </Button.Text>

      <Button.Black href={registerPath} testId="create-account">
        SIGN UP
      </Button.Black>
    </Styled.SignInSignUp>
  );
};

export default UserContext;
