'use client';

import * as React from 'react';
import * as Styled from './styled';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

const DropdownMenu = Styled.Root;

// const DropdownMenuTrigger = Styled.Trigger;

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    noPadding?: boolean;
  }
>(({ className, align = 'end', sideOffset = 4, noPadding = false, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <Styled.Content
      ref={ref}
      sideOffset={sideOffset}
      align={align}
      className={className}
      $noPadding={noPadding}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => <Styled.Item ref={ref} {...props} />);
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
    minor?: boolean;
  }
>(({ className, inset, minor, ...props }, ref) => <Styled.Label ref={ref} inset={inset} minor={minor} {...props} />);
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => <Styled.Separator ref={ref} {...props} />);
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

// export {
// DropdownMenuCheckboxItem,
// DropdownMenuRadioItem,
// DropdownMenuShortcut,
// DropdownMenuGroup,
// DropdownMenuPortal,
// DropdownMenuSub,
// DropdownMenuSubContent,
// DropdownMenuSubTrigger,
// DropdownMenuRadioGroup,
// };

export default Object.assign(DropdownMenu, {
  Content: DropdownMenuContent,
  Trigger: Styled.Trigger,
  Item: DropdownMenuItem,
  Label: DropdownMenuLabel,
  Separator: DropdownMenuSeparator,
  Group: Styled.Group,
  Arrow: Styled.Arrow,
});
