'use client';
import styled from 'styled-components';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { colors } from '@/styles';

export const Root = styled(DropdownMenuPrimitive.Root)`
  position: relative;
`;

export const Trigger = styled(DropdownMenuPrimitive.Trigger)`
  all: unset;
  cursor: pointer;
`;

export const Label = styled(DropdownMenuPrimitive.Label)<{ inset?: boolean; minor?: boolean }>`
  padding: 0.5rem 0.75rem;
  /* font-size: 0.875rem; */
  font-weight: 500;
  ${({ inset }) => inset && `padding-left: 2rem;`}
  ${({ minor }) => minor && `font-size: 12px;`}
  color: ${({ minor }) => (minor ? colors.secondary.mediumGray : colors.primary.black)};
`;

export const Content = styled(DropdownMenuPrimitive.Content)<{ $noPadding?: boolean }>`
  z-index: 999;
  max-height: var(--radix-dropdown-menu-content-available-height);
  min-width: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  border: 1px solid ${colors.secondary.lightGray3};
  background-color: ${colors.primary.white};
  padding: ${(props) => (props.$noPadding ? '0' : '0.25rem')};
  color: var(--popover-foreground);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  /* margin-top: 0px; */

  &[data-state='open'] {
    animation: fadeIn 150ms ease-out;
    animation: zoomIn 150ms ease-out;
  }

  &[data-state='closed'] {
    animation: fadeOut 150ms ease-out;
    animation: zoomOut 150ms ease-out;
  }

  &[data-side='bottom'] {
    animation: slideFromTop 150ms ease-out;
  }

  &[data-side='left'] {
    animation: slideFromRight 150ms ease-out;
  }

  &[data-side='right'] {
    animation: slideFromLeft 150ms ease-out;
  }

  &[data-side='top'] {
    animation: slideFromBottom 150ms ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes zoomIn {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes zoomOut {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.95);
    }
  }

  @keyframes slideFromTop {
    from {
      transform: translateY(-0.5rem);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideFromRight {
    from {
      transform: translateX(0.5rem);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideFromLeft {
    from {
      transform: translateX(-0.5rem);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideFromBottom {
    from {
      transform: translateY(0.5rem);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export const Group = styled(DropdownMenuPrimitive.Group)``;

export const Item = styled(DropdownMenuPrimitive.Item)<{ inset?: boolean }>`
  all: unset;
  cursor: pointer;

  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.125rem;
  padding: 0.5rem 0.75rem;
  /* font-size: 0.875rem; */
  outline: none;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:focus {
    background-color: ${colors.secondary.lightGray2};
    color: var(--accent-foreground);
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  & svg {
    pointer-events: none;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }

  /* if item is wrapped in a link, make spacing is preserved */
  & a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  ${({ inset }) => inset && `padding-left: 2rem;`}
`;

export const Separator = styled(DropdownMenuPrimitive.Separator)`
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  height: 1px;
  background-color: ${colors.secondary.lightGray3};
`;

// export const Arrow = styled(DropdownMenuPrimitive.Arrow)`
//   fill: ${colors.primary.white};
// `;

export const Arrow = styled(DropdownMenuPrimitive.Arrow)<{ $dark?: boolean }>`
  fill: ${({ $dark }) => ($dark ? colors.secondary.darkGray1 : colors.primary.white)};
  margin-top: -1.75px;
  stroke: ${colors.secondary.mediumGray};
  stroke-width: 1px;
  /* Hide the border which overlaps the content above the arrow. */
  clip-path: inset(1px -10px -10px -10px);
`;
